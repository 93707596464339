import { ActiveTag, AssetStatus, PatientType } from 'store/slices/common/types';
import { LocationV2 } from 'store/slices/locations/common/types';

export const realtimeTrackingStatuses = ['on', 'off'] as const;
export type RealtimeTrackingStatus = (typeof realtimeTrackingStatuses)[number];

export const priorities = ['low', 'medium', 'high'] as const;
export type Priority = (typeof priorities)[number];

export const responseStatuses = ['open', 'inProgress', 'closed'] as const;
export type ResponseStatuses = (typeof responseStatuses)[number];

export const responseSubStatuses = [
  'cantHandle',
  'acknowledge',
  'onIt',
  'reject',
  'resolve'
] as const;
export type ResponseSubstatuses = (typeof responseSubStatuses)[number];

export const productTypes = ['asset', 'patient', 'staff'] as const;
export type ProductType = (typeof productTypes)[number];

export const objectTypes = ['asset', 'patient', 'user'] as const;
export type ObjectType = (typeof objectTypes)[number];

export type AlertDeviceType = 'asset' | 'patient' | 'staff' | 'infant';

export interface AlertActiveTag extends ActiveTag {
  assignedObjectId: string;
  deviceType: AlertDeviceType;
}

interface BaseObject {
  activeTag: AlertActiveTag;
}

export interface UserObject extends BaseObject {
  firstName: string;
  jobTitle: string;
  lastName: string;
  staffId: string;
  summary: string;
}

export interface PatientObject extends BaseObject {
  firstName: string;
  lastName: string;
  protocolNo: string;
  roomNumber: string;
  summary: string;
  type: PatientType;
  unitName: string;
}

export interface AssetObject extends BaseObject {
  assetStatus: AssetStatus;
  brandName: string;
  categoryName: string;
  code: string;
  modelName: string;
  modelPhotoPath: string;
}

// TODO: Talk with team for the real time map left panel => card ui(almost same) and ds looks similar
export type ObjectDetails = {
  asset?: AssetObject;
  patient?: PatientObject;
  user?: UserObject;
};

export const alertLogTypes = [
  'alertStarted',
  'retriggered',
  'locationChanged',
  'repetitionTimedOut',
  'alertRepeated',
  'alertInProgress',
  'alertClosed',
  'alertResponded'
] as const;
export type AlertLogType = (typeof alertLogTypes)[number];

export const alertLogGroup = [
  'activities',
  'locationChanged',
  'responses',
  'repetitionAndTimeout'
] as const;
export type AlertLogGroup = (typeof alertLogGroup)[number];

export type AlertLog = {
  hasLocation: boolean;
  id: string;
  location: LocationV2;
  logGroup: AlertLogGroup;
  logTime: string;
  logType: AlertLogType;
  message: string;
};

export type RespondedUser = {
  firstName: string;
  lastName: string;
};

export type AlertResponse = {
  responseTime: string;
  subStatus: ResponseSubstatuses;
  user: RespondedUser;
  userId: string;
};

// TODO: Backend also needs to return substatus for the (closed + rejected) scenario.
export type Alert = {
  alertStartedLog: AlertLog;
  branchId: string;
  createDate: string;
  header: string;
  id: string;
  lastActivityLog: AlertLog;
  lastResponse: AlertResponse;
  objectDetails: ObjectDetails;
  objectId: string; //
  objectType: ObjectType;
  priority: Priority;
  status: ResponseStatuses;
};

export type RecipientAndResponsibleUserType = 'responsible' | 'recipient';
export type AlertUserTypes = 'respondent' | 'unrespondent' | 'recipient';

export type RecipientAndResponsibleUsers = {
  isRespondent: boolean;
  userId: string;
  userType: RecipientAndResponsibleUserType;
};

export type TriggeredFlow = {
  code: string;
  id: string;
  name: string;
};

type TriggeredAction = TriggeredFlow;

export interface AlertDetail extends Omit<Alert, 'alertStartedLog' | 'lastActivityLog'> {
  cameraActionId: string;
  content: string;
  eventRecordingId: string;
  realTimeLocationTracking: RealtimeTrackingStatus;
  triggeredAction: TriggeredAction;
  triggeredWorkflow: TriggeredFlow;
  users: RecipientAndResponsibleUsers[];
}
