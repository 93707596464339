import { Branch } from 'store/slices/branches';
import { Navigate, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';
import { isArrayNullOrEmpty } from '@borda/cat-core';

const Locations = delayedLazy(() => import('views/Locations/Locations'));
const MapSettingsPage = delayedLazy(() => import('views/Locations/MapSettings/MapSettingsPage'));

export default function renderLocationRoutes(rtlsEnabledBranches: Branch[]) {
  return (
    <Route
      element={
        <RouteGuard authKey="locationManagement">
          <SuspenseOutlet />
        </RouteGuard>
      }
      path="location"
    >
      <Route element={<Locations />} path="locations" />
      <Route
        element={
          <RouteGuard authKey="integration" hidden={isArrayNullOrEmpty(rtlsEnabledBranches)}>
            <MapSettingsPage />
          </RouteGuard>
        }
        path="map-settings"
      />
      <Route element={<Navigate replace to="locations" />} index />
    </Route>
  );
}
