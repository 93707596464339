import { AlertHistoryFilter } from './AlertDialog/DialogContent/Common/AlertHistory/types';
import { AlertPanelFilter } from './AlertPanel/types';
import { AlertUsersFilter } from './AlertDialog/DialogContent/Common/RecipientAndResponsibleUsers/AlertUserDialog/types';

export const alertKeys = {
  all: () => ['alerts'],
  dialog: {
    all: () => [...alertKeys.all(), 'dialog'],
    byId: (alertId: string) => ({
      all: () => [...alertKeys.dialog.all(), alertId],
      detail: () => [...alertKeys.dialog.byId(alertId).all(), 'detail'],
      history: {
        all: () => [...alertKeys.dialog.byId(alertId).all(), 'alertHistory'],
        byFilter: (alertHistoryFilter: AlertHistoryFilter) => [
          ...alertKeys.dialog.byId(alertId).history.all(),
          alertHistoryFilter
        ]
      },
      lastResponse: () => [...alertKeys.dialog.byId(alertId).all(), 'lastResponse'],
      users: {
        all: () => [...alertKeys.dialog.byId(alertId).all(), 'alertUsers'],
        byAlertUsersFilter: (alertUsersFilter: AlertUsersFilter) => [
          ...alertKeys.dialog.byId(alertId).users.all(),
          alertUsersFilter
        ]
      }
    })
  },
  panel: {
    all: () => [...alertKeys.all(), 'panel'],
    list: {
      all: () => [...alertKeys.panel.all(), 'list'],
      byFilter: (alertFilter: AlertPanelFilter) => [...alertKeys.panel.list.all(), alertFilter]
    }
  }
};
