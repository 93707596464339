import {
  ASSET,
  ASSETCONFIGURATION,
  ASSETDASHBOARD,
  ATTRIBUTE_CONFIGURATION,
  CONTRACT_LIST,
  DESIGNSYSTEM_SHOWCASE,
  DEVICES,
  INTEGRATION,
  MEDICAL_UNIT_MANAGEMENT,
  METRICSCONFIGURATION,
  NOTIFICATION_MANAGEMENT,
  PATIENT,
  PLAN,
  REALTIME_TRACKING,
  REQUEST,
  TASK,
  TASKCONFIGURATION,
  WORKFLOW
} from 'routes/constant-route';
import {
  AssetListingIcon,
  AssetSettingsIcon,
  AttributeIcon,
  CatIconBaseProps,
  ClipboardListIcon,
  ContractIcon,
  DashboardIcon,
  DeviceIcon,
  LocationIcon,
  MedicalUnitIcon,
  MetricsConfigurationsIcon,
  NotificationIcon,
  PlanIcon,
  RequestIcon,
  TaskIcon,
  TaskSettingsIcon,
  Tracking2Icon,
  UsersIcon,
  WorkflowIcon
} from '@borda/cat-icons';
import { AuthKeys, AuthorizationInfo } from 'store/slices/session';
import { Branch } from 'store/slices/branches';
import { ProductKeys } from 'utils/settings';
import { TransKeys } from 'utils/localization/types/trans-keys';
import { WorkType } from 'store/slices/common/types';
import { isArrayNullOrEmpty } from '@borda/cat-core';
import React from 'react';

export type NavigationItem = {
  authKeys?: AuthKeys[];
  development?: boolean;
  disabled?: boolean;
  dynamicAuthCallback?: (authInfo: AuthorizationInfo) => boolean;
  hidden?: boolean;
  href: string;
  icon?: (props: CatIconBaseProps) => React.ReactElement;
  items?: NavigationItem[];
  products?: ProductKeys[];
  title: TransKeys;
};

export type NavigationGroup = {
  depth?: number;
  items: NavigationItem[];
  key?: number | string;
  pathname?: string;
  subheader?: TransKeys;
  subheaderVisible?: boolean;
  subheader_short?: TransKeys;
};

type Props = {
  cyleraEnable: boolean;
  rtlsEnabledBranches: Branch[];
};

const getNavigations = (props: Props) => {
  const { cyleraEnable, rtlsEnabledBranches } = props;

  const navigations: NavigationGroup[] = [
    {
      items: [
        {
          authKeys: ['assetDashboard'],
          href: ASSETDASHBOARD,
          icon: DashboardIcon,
          products: ['asset'],
          title: 'layout.navbar.asset_dashboard'
        },
        {
          hidden: isArrayNullOrEmpty(rtlsEnabledBranches),
          href: REALTIME_TRACKING,
          icon: Tracking2Icon,
          title: 'layout.navbar.realtime_tracking'
        }
      ],
      subheader: 'layout.navbar.dashboard',
      subheaderVisible: false,
      subheader_short: 'layout.navbar.dashboard_short'
    },
    {
      items: [
        {
          authKeys: ['taskList'],
          href: TASK,
          icon: TaskIcon,
          products: ['asset'],
          title: 'layout.navbar.my_tasks'
        },
        {
          href: REQUEST,
          icon: RequestIcon,
          products: ['asset'],
          title: 'layout.navbar.my_requests'
        },
        {
          authKeys: ['plan'],
          href: PLAN,
          icon: PlanIcon,
          products: ['asset'],
          title: 'layout.navbar.plans'
        }
      ],
      subheader: 'layout.navbar.tasks',
      subheader_short: 'layout.navbar.tasks_short'
    },
    {
      items: [
        {
          href: ASSET,
          icon: AssetListingIcon,
          products: ['asset'],
          title: 'layout.navbar.assets'
        },
        {
          authKeys: ['contracts'],
          href: CONTRACT_LIST,
          icon: ContractIcon,
          products: ['asset'],
          title: 'layout.navbar.contracts'
        }
      ],
      subheader: 'layout.navbar.asset_management',
      subheader_short: 'layout.navbar.asset_management_short'
    },
    {
      items: [
        {
          authKeys: ['patient'],
          href: PATIENT,
          icon: ClipboardListIcon,
          products: ['patient'],
          title: 'layout.navbar.patients'
        }
      ],
      subheader: 'layout.navbar.patient_management',
      subheader_short: 'layout.navbar.patient_management_short'
    },
    {
      items: [
        {
          authKeys: ['assetConfiguration'],
          href: ASSETCONFIGURATION,
          icon: AssetSettingsIcon,
          products: ['asset'],
          title: 'layout.navbar.asset_configurations'
        },
        {
          authKeys: ['taskConfiguration'],
          dynamicAuthCallback: (authInfo) => {
            const supportedWorkTypes: WorkType[] = ['breakdown', 'maintenance', 'calibration'];

            return authInfo.mainCategories.some(
              (c) => supportedWorkTypes.filter((wt) => c.workTypes.includes(wt)).length > 0
            );
          },
          href: TASKCONFIGURATION,
          icon: TaskSettingsIcon,
          products: ['asset'],
          title: 'layout.navbar.task_configurations'
        },
        {
          authKeys: ['workflowsAndActions'],
          href: WORKFLOW,
          icon: WorkflowIcon,
          title: 'layout.navbar.workflows_and_actions'
        },
        {
          authKeys: ['attributeConfiguration'],
          href: ATTRIBUTE_CONFIGURATION,
          icon: AttributeIcon,
          title: 'layout.navbar.attribute_configurations'
        },
        {
          authKeys: ['metricsConfiguration'],
          href: METRICSCONFIGURATION,
          icon: MetricsConfigurationsIcon,
          products: ['asset'],
          title: 'layout.navbar.metrics_configurations'
        },
        {
          authKeys: ['integration'],
          hidden: !cyleraEnable && isArrayNullOrEmpty(rtlsEnabledBranches),
          href: INTEGRATION,
          icon: DashboardIcon,
          title: 'layout.navbar.integrations'
        },
        {
          authKeys: ['locationManagement'],
          href: '/location',
          icon: LocationIcon,
          title: 'layout.navbar.locations'
        },
        {
          authKeys: ['devices'],
          href: DEVICES,
          icon: DeviceIcon,
          title: 'layout.navbar.devices'
        },
        {
          authKeys: ['userManagement'],
          href: '/user',
          icon: UsersIcon,
          title: 'layout.navbar.users'
        },
        {
          authKeys: ['medicalUnits'],
          href: MEDICAL_UNIT_MANAGEMENT,
          icon: MedicalUnitIcon,
          products: ['patient'],
          title: 'layout.navbar.medical_units'
        },
        {
          authKeys: ['notificationConfiguration'],
          href: NOTIFICATION_MANAGEMENT,
          icon: NotificationIcon,
          title: 'layout.navbar.notification_management'
        },
        {
          development: true,
          href: DESIGNSYSTEM_SHOWCASE,
          icon: DashboardIcon,
          title: 'layout.design_system'
        }
      ],
      subheader: 'layout.navbar.configuration',
      subheader_short: 'layout.navbar.configuration_short'
    }
  ];

  return navigations;
};

export default getNavigations;
