const medicalUnitTypeUrls = {
  addMedicalUnitType: () => `/api/patient/unit-types`,
  deleteMedicalUnitType: (unitTypeId: string) => `/api/patient/unit-types/${unitTypeId}`,
  getMedicalUnitTypesByBranchId: () => `/api/patient/unit-types`,
  updateMedicalUnitType: (unitTypeId: string) => `/api/patient/unit-types/${unitTypeId}`
};

const medicalUnitUrls = {
  addMedicalUnit: () => `/api/patient/units`,
  deleteMedicalUnit: (unitId: string) => `/api/patient/units/${unitId}`,
  getMedicalUnitsByBranches: () => `/api/patient/units/search/by-branches`,
  updateMedicalUnit: (unitId: string) => `/api/patient/units/${unitId}`
};

const patientUrls = {
  getPatientDetailById: (id: string) => `/api/patient/patients/${id}/detail`,
  getTagHistory: () => `/api/patient/patients/tag-history/search`,
  searchPatients: () => '/api/patient/patients/search/by-filters',
  searchPatientsByIds: () => '/api/patient/patients/search/by-ids',
  updatePatient: (id: string) => `/api/patient/patients/${id}`,
  updateSafeLocations: (id: string) => `/api/patient/patients/${id}/safe-locations`
};

const patientListFilterUrls = {
  deleteSavedFilter: (filterId: string) => `/api/patient/filters/${filterId}`,
  getPatientListSavedFilters: () => `/api/patient/filters`,
  saveFilter: () => `/api/patient/filters`
};

const actionUrls = {
  getPatientActionsValidation: () => `/api/patient/patients/check-actions`
};

export default {
  ...medicalUnitTypeUrls,
  ...medicalUnitUrls,
  ...patientUrls,
  ...patientListFilterUrls,
  ...actionUrls
};
