import { Branch } from 'store/slices/branches';
import { MainCategory, User, isUserAuthorized } from 'store/slices/session';
import { getDevelopmentFeaturesEnabled, isUserAuthorizedOnProducts } from 'utils/settings';
import { isArrayNullOrEmpty } from '@borda/cat-core';
import getNavigations, { NavigationGroup } from './navigations';

type GetFilteredNavigationsProps = {
  branches: Branch[];
  cyleraEnable: boolean;
  mainCategories: MainCategory[];
  rtlsEnabledBranches: Branch[];
  user: User;
};

export const getFilteredNavigations = (props: GetFilteredNavigationsProps) => {
  const { branches, cyleraEnable, mainCategories, rtlsEnabledBranches, user } = props;
  const developmentFeaturesEnabled = getDevelopmentFeaturesEnabled();
  const filteredNavigations: NavigationGroup[] = [];

  const navigations = getNavigations({ cyleraEnable, rtlsEnabledBranches });
  navigations.forEach((navigationGroup) => {
    const filteredItems = navigationGroup.items.filter((navigationItem) => {
      if (navigationItem.hidden) {
        return false;
      }

      if (!developmentFeaturesEnabled && navigationItem.development) {
        return false;
      }

      const authorizedByProductKeys = isUserAuthorizedOnProducts(navigationItem.products, branches);
      if (!authorizedByProductKeys) {
        return false;
      }

      const noAuthRequired = isArrayNullOrEmpty(navigationItem.authKeys);
      const authorizedByAuthKeys =
        noAuthRequired ||
        navigationItem.authKeys.some((authKey) => {
          const authorized = isUserAuthorized(user, authKey);
          return authorized;
        });

      if (!authorizedByAuthKeys) {
        return false;
      }

      const authorizedDynamically =
        navigationItem.dynamicAuthCallback?.({
          mainCategories
        }) ?? true;

      return authorizedDynamically;
    });

    if (filteredItems.length > 0) {
      filteredNavigations.push({
        ...navigationGroup,
        items: filteredItems
      });
    }
  });

  return filteredNavigations;
};
