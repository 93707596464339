import { CatPaper } from '@borda/cat-ui';
import { Drawer, Theme, styled, useMediaQuery } from '@borda/cat-mui';
import { catColors } from '@borda/cat-core';
import { getFilteredNavigations } from './navigationHelpers';
import { selectAllBranches, selectRtlsEnabledBranches } from 'store/slices/branches';
import {
  selectAllMainCategories,
  selectClientSettings,
  selectIsUserAuthorized,
  selectSessionUser
} from 'store/slices/session';
import { useMemo } from 'react';
import { useTypedSelector } from 'hooks';
import BordaIcon from './BordaIcon';
import CustomScrollbar from 'react-custom-scrollbars-2';
import MinimizedAssetImport from 'views/Asset/Assets/Import/MinimizedAssetImport/MinimizedAssetImport';
import NavbarList from './NavbarList';
import clsx from 'clsx';

const StyledDrawer = styled(Drawer)(() => ({
  '.MuiDrawer-paper': {
    backgroundColor: catColors.lightGrey.main,
    borderRight: 'none'
  },
  '.MuiDrawer-paperAnchorLeft': {
    background: 'transparent',
    boxShadow: 'none',
    overflow: 'inherit'
  }
}));

function NavBar() {
  const showLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    defaultMatches: null
  });

  const user = useTypedSelector(selectSessionUser);
  const mainCategories = useTypedSelector(selectAllMainCategories);
  const rtlsEnabledBranches = useTypedSelector(selectRtlsEnabledBranches);
  const branches = useTypedSelector(selectAllBranches);

  const { cyleraEnable } = useTypedSelector(selectClientSettings);

  const navs = useMemo(
    () =>
      getFilteredNavigations({
        branches,
        cyleraEnable,
        mainCategories,
        rtlsEnabledBranches,
        user
      }),
    [branches, cyleraEnable, mainCategories, rtlsEnabledBranches, user]
  );

  const authorizedForAssetImport = useTypedSelector((state) =>
    selectIsUserAuthorized(state, 'assetImport')
  );

  return (
    <StyledDrawer anchor="left" open variant="persistent">
      <CatPaper
        className={clsx(
          'shadow-5 m-2 flex h-screen flex-col justify-between rounded-3xl px-1 pt-6 duration-500',
          showLarge ? 'w-48' : 'w-[72px]'
        )}
      >
        <BordaIcon />
        <div className="h-4" />
        <CustomScrollbar className="mb-1.5">
          <div className="grid gap-8 px-1">
            {navs.map((list) => (
              <NavbarList key={list.subheader} navigation={list} />
            ))}
          </div>
        </CustomScrollbar>
        {authorizedForAssetImport && (
          <div className="p-2">
            <MinimizedAssetImport />
          </div>
        )}
      </CatPaper>
    </StyledDrawer>
  );
}

export default NavBar;
