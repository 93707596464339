import { DynamicListDefinition } from '@borda/cat-core';
import { TrackableDevice } from './types';

export const initialColumnDynamicData: DynamicListDefinition<TrackableDevice> = {
  columns: [
    {
      column: 'macAddress',
      visible: true,
      width: 150
    },
    {
      column: 'deviceType',
      visible: true,
      width: 50
    },
    {
      column: 'assignedObjectDetails',
      visible: true,
      width: 150
    },
    {
      column: 'branchId',
      visible: true,
      width: 150
    },
    {
      column: 'activeLocation',
      visible: true,
      width: 150
    },
    {
      column: 'lastSeen',
      visible: true,
      width: 150
    },
    {
      column: 'firmwareVersion',
      visible: true,
      width: 100
    },
    {
      column: 'status',
      visible: true,
      width: 150
    },
    {
      column: 'batteryLevel',
      visible: true,
      width: 150
    }
  ],
  sorts: [{ column: 'lastSeen', direction: 'desc' }]
};
