import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UserFilter } from './types';
import { UserStatus } from '../common/types';
import { emptyFilter, userFilterInitialState } from './data';

const filterSlice = createSlice({
  initialState: userFilterInitialState,
  name: 'filter',
  reducers: {
    clearAllFilter: (draft) => {
      draft.activeFilter = { ...emptyFilter };
    },
    removeAdditionalPermissions: (draft, action: PayloadAction<string>) => {
      const permission = action.payload;
      const hasPermission = draft.activeFilter.additionalPermissions.includes(permission);
      if (hasPermission) {
        const index = draft.activeFilter.additionalPermissions.findIndex(
          (item) => item === permission
        );
        draft.activeFilter.additionalPermissions.splice(index, 1);
      }
      draft.activeFilter.extraFilters = emptyFilter.extraFilters;
    },
    removeAssetManagementRole: (draft, action: PayloadAction<string>) => {
      const role = action.payload;
      const hasRole = draft.activeFilter.roles.includes(role);
      if (hasRole) {
        const index = draft.activeFilter.roles.findIndex((item) => item === role);
        draft.activeFilter.roles.splice(index, 1);
      }
      draft.activeFilter.extraFilters = emptyFilter.extraFilters;
    },
    removeBranch: (draft, action: PayloadAction<string>) => {
      const branch = action.payload;
      const hasBranch = draft.activeFilter.branches.includes(branch);
      if (hasBranch) {
        const index = draft.activeFilter.branches.findIndex((item) => item === branch);
        draft.activeFilter.branches.splice(index, 1);
      }
      draft.activeFilter.extraFilters = emptyFilter.extraFilters;
    },
    removeDepartment: (draft, action: PayloadAction<string>) => {
      const department = action.payload;
      const hasBranch = draft.activeFilter.departments.includes(department);
      if (hasBranch) {
        const index = draft.activeFilter.departments.findIndex((item) => item === department);
        draft.activeFilter.departments.splice(index, 1);
      }
      draft.activeFilter.extraFilters = emptyFilter.extraFilters;
    },
    removeEmail: (draft) => {
      draft.activeFilter.email = emptyFilter.email;
      draft.activeFilter.extraFilters = emptyFilter.extraFilters;
    },
    removeFirstName: (draft) => {
      draft.activeFilter.firstName = emptyFilter.firstName;
      draft.activeFilter.extraFilters = emptyFilter.extraFilters;
    },
    removeGeneralAdmin: (draft) => {
      draft.activeFilter.generalAdmin = false;
      draft.activeFilter.extraFilters = emptyFilter.extraFilters;
    },
    removeJobTitle: (draft, action: PayloadAction<string>) => {
      const jobTitle = action.payload;
      const hasJobTitle = draft.activeFilter.jobTitles.includes(jobTitle);
      if (hasJobTitle) {
        const index = draft.activeFilter.jobTitles.findIndex((item) => item === jobTitle);
        draft.activeFilter.jobTitles.splice(index, 1);
      }
      draft.activeFilter.extraFilters = emptyFilter.extraFilters;
    },
    removeLastName: (draft) => {
      draft.activeFilter.lastName = emptyFilter.lastName;
      draft.activeFilter.extraFilters = emptyFilter.extraFilters;
    },
    removeLastUpdate: (draft) => {
      draft.activeFilter.lastUpdateFilter = emptyFilter.lastUpdateFilter;
      draft.activeFilter.extraFilters = emptyFilter.extraFilters;
    },
    removeStaffId: (draft) => {
      draft.activeFilter.staffId = emptyFilter.staffId;
      draft.activeFilter.extraFilters = emptyFilter.extraFilters;
    },
    removeUserAdded: (draft) => {
      draft.activeFilter.userAddedFilter = emptyFilter.userAddedFilter;
      draft.activeFilter.extraFilters = emptyFilter.extraFilters;
    },
    removeUserStatus: (draft, action: PayloadAction<UserStatus>) => {
      const status = action.payload;
      const hasStatus = draft.activeFilter.userStatuses.includes(status);
      if (hasStatus) {
        const index = draft.activeFilter.userStatuses.findIndex((item) => item === status);
        draft.activeFilter.userStatuses.splice(index, 1);
      }
      draft.activeFilter.extraFilters = emptyFilter.extraFilters;
    },
    updateActiveFilter: (draft, action: PayloadAction<UserFilter>) => {
      const draftFilter = action.payload;
      draft.activeFilter = { ...draftFilter };
    },
    updateExtraFilter: (draft, action: PayloadAction<UserStatus>) => {
      const status = action.payload;
      const { extraFilters } = draft.activeFilter;

      if (action.payload) {
        const index = extraFilters.findIndex((item) => item === status);
        if (index > -1) {
          extraFilters.splice(index, 1);
        } else {
          extraFilters.push(status);
        }
      }
    }
  }
});

export default filterSlice.reducer;

export const {
  clearAllFilter,
  removeAdditionalPermissions,
  removeAssetManagementRole,
  removeBranch,
  removeDepartment,
  removeEmail,
  removeFirstName,
  removeGeneralAdmin,
  removeJobTitle,
  removeLastName,
  removeLastUpdate,
  removeStaffId,
  removeUserAdded,
  removeUserStatus,
  updateActiveFilter,
  updateExtraFilter
} = filterSlice.actions;
