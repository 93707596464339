import { ClientConfiguration, SessionState, Settings, User } from './types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
  initialState: {
    client: { currencies: [], cyleraEnable: false },
    status: 'inProgress',
    useSocketForNotification: false
  } as SessionState,
  name: 'session',
  reducers: {
    accountSettingsSet: (draft, action: PayloadAction<Settings>) => {
      draft.account = { settings: action.payload };
    },
    clientConfigurationLoaded: (draft, action: PayloadAction<ClientConfiguration>) => {
      const { currencies, cyleraEnable } = action.payload;
      draft.client.cyleraEnable = cyleraEnable;
      draft.client.currencies = currencies;
    },
    firebaseSubscriptionFailed: (draft) => {
      draft.useSocketForNotification = true;
    },
    loginCallbackInvalid: (draft) => {
      draft.status = 'loggedOut';
      draft.user = null;
    },
    logout: (draft) => {
      draft.status = 'loggedOut';
      draft.user = null;
    },
    silentRefresh: (draft, action: PayloadAction<User>) => {
      const user = { ...action.payload };
      draft.status = 'loggedIn';
      draft.user = user;
    },
    silentRefreshFailed: (draft) => {
      draft.status = 'loggedOut';
      draft.user = null;
    },
    userExpired: (draft) => {
      draft.status = 'inProgress';
    },
    userLoadedFromStore: (draft, action: PayloadAction<User>) => {
      const user = { ...action.payload };
      draft.status = 'loggedIn';
      draft.user = user;
    },
    userLogin: (draft, action: PayloadAction<User>) => {
      const user = { ...action.payload };

      draft.status = 'loggedIn';
      draft.user = user;
    },
    userNotFoundInStore: (draft) => {
      draft.status = 'inProgress';
    },
    userSignedOutFromAnotherTab: (draft) => {
      draft.status = 'loggedOut';
      draft.user = null;
    }
  }
});

const { firebaseSubscriptionFailed } = sessionSlice.actions;
export { firebaseSubscriptionFailed };
