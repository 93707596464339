const assetUrls = {
  getAssetDetail: (id: string) => `/api/gw/registration/assets/${id}/details`,
  getAssetHistory: (assetId: string) => `/api/gw/registration/assets/${assetId}/update-histories`,
  getAssetListCustomFields: () => `/api/gw/registration/forms/current-user/fields`,
  getDeleteAssetCounts: () => '/api/gw/registration/assets/deletion-preparation-summary',
  getDeleteCategoryTaskAssetCounts: (id: string) =>
    `/api/gw/registration/assets/by-categorybrandmodel/${id}/deletion-preparation-summary`,
  getTransferAssetCounts: () => '/api/gw/registration/assets/transfer-preparation-summary'
};

const formFieldUrls = {
  getFormDetailByFormId: (formId: string) => `/api/gw/registration/forms/${formId}`,
  getFormList: () => `/api/gw/registration/forms`
};

const locationUrls = {
  getLocations: (branchId: string, parentLocationId?: string) =>
    `/api/gw/location/locations/branch/${branchId}?parentLocationId=${parentLocationId ?? ''}`
};

const protectionUrls = {
  getCalibrationDetail: (id: string) => `/api/gw/protection/calibrations/${id}/task-detail`,
  getCalibrationUpdateHistories: (protectionId: string) =>
    `/api/gw/protection/calibrations/${protectionId}/update-histories`,
  getMaintenanceDetail: (id: string) => `/api/gw/protection/maintenances/${id}/task-detail`,
  getMaintenanceUpdateHistories: (protectionId: string) =>
    `/api/gw/protection/maintenances/${protectionId}/update-histories`
};

const protectionCalendarUrls = {
  getCalendarTasks: () => `/api/gw/protection/protectioncalendars/scheduled-list`,
  getUnscheduledList: () => `/api/gw/protection/protectioncalendars/unscheduled-list`
};

const tasksUrls = {
  getMyRequestList: () => `/api/gw/tasks/my-request-list`,
  getWFCList: () => `/api/gw/tasks/waiting-for-confirmation-list`
};

const userUrls = {
  getUserDetailsById: (id: string) => `/api/gw/user/users/${id}/details`,
  searchUsers: () => '/api/gw/user/users/search',
  searchUsersByIds: () => '/api/gw/user/users/search/by-ids'
};

const breakdownUrls = {
  getBreakdownAsset: (assetId: string) => `/api/gw/breakdown/breakdowns/assets/${assetId}/detail`,
  getBreakdownDetail: (breakdownId: string) =>
    `/api/gw/breakdown/breakdowns/${breakdownId}/task-detail`,
  getBreakdownHistory: (breakdownId: string) =>
    `/api/gw/breakdown/breakdowns/${breakdownId}/update-histories`,
  getMyRequestDetail: (taskId: string) =>
    `/api/gw/breakdown/breakdowns/${taskId}/my-request-detail`,
  getWfcDetail: (breakdownId: string) =>
    `/api/gw/breakdown/breakdowns/${breakdownId}/waiting-for-confirmation-detail`
};

const clientConfigurationUrls = {
  getClientConfiguration: () => '/api/gw/api/client-configuration'
};

const planUrls = {
  getPlanSummary: (planId: string) => `/api/gw/plans/${planId}/summary`,
  getPlanUpcomingTaskSummary: () => '/api/gw/plans/schedule-upcoming-task-summary',
  searchPlans: () => '/api/gw/plans/search'
};

const attributeUrls = {
  getAttributesByObject: (object: string) => `/api/gw/shared/attributes/object-type/${object}`
};

export default {
  ...attributeUrls,
  ...assetUrls,
  ...clientConfigurationUrls,
  ...formFieldUrls,
  ...locationUrls,
  ...protectionUrls,
  ...protectionCalendarUrls,
  ...tasksUrls,
  ...userUrls,
  ...breakdownUrls,
  ...planUrls
};
